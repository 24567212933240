const routes = {
  CREATE: "/create",
  LOGIN: "/",
  FORGET_PASSWORD: "/forgot-password",
  CHANGE_PASSWORD: "/change-password",

  //dashboard
  Dashboard: "/dashboard",
  CREATE_INDEX_TEMPLATE: "create-index-template",

  //user
  USER_MANAGEMNET: "/user-management",
  USER_DETAILS: "user-detail",
  USER_ASSETS: "user-assets",

  //fund manager
  CREATE_FUND_MANAGER: "create-fundManager",
  FUND_MANAGER_DETAILS: "fund-manager-details",
  MANAGE_FUND_MANAGER: "/manage-fund-manager",

  //platform manager
  MANAGE_PLATFORM_MANAGER: "/manage-platform-manager",
  CREATE_PLATFORM_MANAGER: "create",

  //supported token
  SUPPORTED_TOKEN: "supported-token",

  //request
  REQUEST: "/request",

  //setting
  PROFILE: "/profile",
  SETTINGS: "/settings",

  //others
  INSTANCE: "instance",
  INSTANCE_DETAIL: "instance-detail",
};
export default routes;
