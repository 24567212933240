import { StyledLandingWrapper } from "./style";
import logo from "../../assets/icons/logo.svg";
import { useEffect } from "react";
import localStorageHelper from "../../common/localStorageHelper";
import { useTheme } from "styled-components";

export default function Landing() {
  const theme = useTheme();

  useEffect(() => {
    localStorageHelper.setLocalStorageItem("theme", theme);
  }, [theme]);

  return (
    <StyledLandingWrapper>
      <div>
        <img src={logo} alt="" />
        <h1>Desktop App v3.5</h1>
      </div>
    </StyledLandingWrapper>
  );
}
