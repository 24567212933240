import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import routes from "../constants/routes";
import { useAppDetails } from "../hooks/useAppDetails";

export default function PublicRoutes() {
  const { loginDetail } = useAppDetails();

  if (!loginDetail.token) {
    return <Outlet />;
  } else {
    return <Navigate to={routes.Dashboard} replace />;
  }
}
