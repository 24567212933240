import { ThemeProvider } from "styled-components";
import { DarkTheme } from "./Themes";
import MainSectionWrapper from "./components/mainSection";
import "./App.css";
import { Amplify } from "aws-amplify";
import { useAppDetails } from "./hooks/useAppDetails";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

Amplify.configure({
  aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID.replace(";", ""),
  aws_user_pools_web_client_id: process.env.REACT_APP_CLIENT_ID.replace(
    ";",
    ""
  ),
});

function App() {
  const { themeDetail } = useAppDetails();

  // useEffect(() => {
  //   if (!themeDetail?.themeType) {
  //     dispatch(changeTheme({ themeType: "light" }));
  //   }
  // }, [dispatch, themeDetail]);

  return (
    <>
      <ThemeProvider theme={DarkTheme}>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          rtl={false}
          theme={themeDetail.themeType}
        />
        <div
          style={
            themeDetail.themeType === "light"
              ? { backgroundColor: "white" }
              : { backgroundColor: "black" }
          }
        >
          <MainSectionWrapper />
        </div>
      </ThemeProvider>
    </>
  );
}

export default App;
