import ActionTypes from "../variables";
const initialState = {};
export const userTypeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_USER_TYPE_DATA:
      return {
        ...state,
        ...payload,
      };
    case ActionTypes.REMOVE_USER_TYPE_DATA:
      return null;
    case ActionTypes.ADD_WALLET_ADDRESS:
      return {
        ...state,
        wallet_address: payload,
      };
    default:
      return state;
  }
};
