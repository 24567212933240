import styled from "styled-components";

export const StyledLandingWrapper = styled.div`
  background: ${(props) => props.theme.colors.bodyBackgroundColor};
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  img {
    width: 150px;
    height: 84px;
  }
  h1 {
    color: ${(props) => props.theme.colors.inputBackgroundColor};
    font-size: ${(props) => props.theme.fontSize[10] + "px"};
    height: 71px;
  }
`;
