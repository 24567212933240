import ActionTypes from "../variables";
const initialState = []
export const themeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.CHANGE_THEME:
      return {
        ...state, ...payload
      }
    default:
      return state;
  }
}