const setLocalStorageItem = (key, value) => {
  const stringify = JSON.stringify(value);
  localStorage.setItem(key, stringify);
};

const removeLocalStorageItem = (key) => {
  localStorage.removeItem(key);
};

const getLocalStorageItem = (key) => {
  const item = localStorage.getItem(key);
  try {
    return JSON.parse(item);
  } catch (e) {
    return item;
  }
};

const localStorageHelper = Object.freeze({
  setLocalStorageItem,
  removeLocalStorageItem,
  getLocalStorageItem,
});

export default localStorageHelper;
