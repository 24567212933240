import { combineReducers } from "redux";
import { loginReducer } from "./loginReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { themeReducer } from "./themeReducer";
import { userTypeReducer } from "./userTypeReducer";

const persistConfig = {
  key: "persist-key",
  storage,
};

const appPersistReducer = combineReducers({
  loginDetail: loginReducer,
  themeDetail: themeReducer,
  userTypeData: userTypeReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }
  return appPersistReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, appPersistReducer);
const basicReducers = combineReducers({
  // appDetails: appReducers,
  perssisted: persistedReducer,
});

export default rootReducer;
export { basicReducers };
