const Action = {
  SET_ACCESS_TOKEN: "SET_ACCESS_TOKEN",
  SET_ID_TOKEN: "SET_ID_TOKEN",
  REMOVE_ACCESS_TOKEN: "REMOVE_ACCESS_TOKEN",
  //userAction
  SET_USER_DATA: "SET_USER_DATA",
  REMOVE_USER_DATA: "REMOVE_USER_DATA",

  //userType
  SET_USER_TYPE_DATA: "SET_USER_TYPE_DATA",
  REMOVE_USER_TYPE_DATA: "REMOVE_USER_TYPE_DATA",
  ADD_WALLET_ADDRESS: "ADD_WALLET_ADDRESS",

  //signupAction
  SET_USER_DETAILS: "SET_USER_DETAILS",
  UPDATE_SIGNUP_USER_DETAILS: "UPDATE_SIGNUP_USER_DETAILS",
  THEME_TYPE: "THEME_TYPE",
  CHANGE_THEME: "CHANGE_THEME",
  SET_INVEST_DETAILS: "SET_INVEST_DETAILS",
};

export default Action;
