import React from "react";
import { Route, Routes } from "react-router-dom";
import { StyledMainSection } from "./MainSection";
import routes from "../../constants/routes";
import { Suspense } from "react";
import LandingPage from "../../pages/landingPage";
import PublicRoutes from "../../routes/PublicRoutes";
const Login = React.lazy(() => import("../../pages/loginPage"));
const ForgetPassword = React.lazy(() => import("../../pages/forget-password"));
const ProtectedRoutes = React.lazy(() =>
  import("../../routes/ProtectedRoutes")
);

export default function MainSectionWrapper() {
  return (
    <>
      <StyledMainSection>
        <Routes>
          <Route element={<PublicRoutes />}>
            <Route
              path={routes.LOGIN}
              element={
                <Suspense fallback={<LandingPage />}>
                  <Login />
                </Suspense>
              }
            />
            <Route
              path={routes.FORGET_PASSWORD}
              element={
                <Suspense fallback={<LandingPage />}>
                  <ForgetPassword />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="*"
            element={
              <Suspense fallback={<LandingPage />}>
                <ProtectedRoutes />
              </Suspense>
            }
          />
        </Routes>
      </StyledMainSection>
    </>
  );
}
