import styled from "styled-components";

export const StyledMainSection = styled.div`
  .routing-style {
    display: flex;
    width: 100%;

    /* width */
    .right-section::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    .right-section::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }

    /* Handle */
    .right-section::-webkit-scrollbar-thumb {
      margin-top: 50px;
      background: grey;
      border-radius: 10px;
    }
    .right-section {
      height: 100vh;
      width: 80%;
      padding-top: 40px;
      scrollbar-width: 5px;
      overflow-y: scroll;
      padding-bottom: 40px;
      background: ${(props) => props.theme.colors.bodyBackgroundColor};
      .all-card {
        display: flex;
        justify-content: start;
        align-items: center;
        column-gap: 20px;
        flex-wrap: wrap;
        padding-left: 3px;
        padding-bottom: 18px;
        background: ${(props) => props.theme.colors.bodyBackgroundColor};
      }
    }
  }
`;
